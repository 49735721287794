<template>
  <section>
    <header-coil :title="title" :urlEN="urlEN" class="text-c_darkorange" />
    <div class="grid gap-10 p-10 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      <article class="shadow rounded p-4">
        <a
          href="https://www.udem.edu.mx/es/institucional/congreso-red-latam-coil"
          target="_blank"
        >
          <img src="@/assets/images/eventos/4_latam_coil-2024.jpg" alt="" />
          <p class="p-4">4º Congreso Red Latam COIL 2024</p>
        </a>
        <p class="text-sm text-c_lightblue">
          Descarga la
          <a
            href="docs/4_latam_coil.pdf"
            download="4_latam_coil.pdf"
            class="underline"
            >convocatoria</a
          >
        </p>
      </article>
    </div>
  </section>
</template>

<style scoped>
a > p {
  @apply text-c_darkorange;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "EventsView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Eventos académicos COIL",
      urlEN: "https://coil.unam.mx/en/#/events",
    };
  },
};
</script>
