<template>
  <nav>
    <ul>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'about-coil' }">
          Acerca de COIL UNAM
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'interested' }"
          >¿Te interesa diseñar un COIL?
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'testimonials' }">Testimonios</router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'coilaborando' }"
          >COILaborando Boletín
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'presence' }"
          >COIL en los medios
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'publications' }">
          Publicaciones UNAM sobre COIL
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'resources' }"
          >Recursos sobre COIL</router-link
        >
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'events' }">
          Eventos académicos COIL
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'jornadaCoil' }">
          Primera Jornada COIL UNAM 2023
        </router-link>
      </li>
      <li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'comission' }"> Consejeros COIL </router-link>
      </li>
      <!--<li class="py-2 mb-2 pl-1 block my-1 border-b border-c_darkblue">
        <router-link :to="{ name: 'contact' }">Contacto</router-link>
      </li>-->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuCoil",
};
</script>
