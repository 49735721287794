<template>
  <div id="app" class="min-h-screen flex justify-center items-center">
    <section
      class="w-full grid content-center p-2 md:p-4 lg:p-6 rounded bg-coil-main bg-no-repeat bg-center bg-cover min-h-screen"
    >
      <header class="text-center">
        <img
          v-if="anchoVentana >= 640"
          src="@/assets/images/coil_logo.png"
          alt="COIL UNAM"
          class="mx-auto w-full xl:max-w-5xl"
        />
        <img
          v-else
          src="@/assets/images/coil-header320.png"
          alt="COIL UNAM"
          class="mx-auto w-full xl:max-w-5xl"
        />
      </header>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  mounted() {
    this.anchoVentana = window.innerWidth;
  },
  data() {
    return {
      anchoVentana: 0,
    };
  },
};
</script>
